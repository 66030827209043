import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  muiStyles,
  InfoIcon,
  Typography,
  colors,
} from "@intouchhealth/cig-components";

const { styled } = muiStyles;

const TESTING_ENV =
  process.env.REACT_APP_ENV_BANNER === "cat" ||
  process.env.REACT_APP_ENV_BANNER === "staging";

const Banner: React.FC = () => {
  return (
    <>
      {TESTING_ENV && (
        <EnvBanner>
          <InfoIcon
            style={{ marginRight: "8px" }}
            iconSize="m"
            fill={"#422981"}
          />
          <EnvText type="h6" fontWeight="bold">
            {process.env.REACT_APP_ENV_BANNER?.toUpperCase()} Environment
          </EnvText>
          <EnvMessage type="h6">
            Always verify the environment before making changes.
          </EnvMessage>
        </EnvBanner>
      )}
    </>
  );
};

const EnvBanner = styled("div")({
  position: "fixed",
  top: 0,
  width: "100%",
  height: "36px",
  backgroundColor: "#EBE2FF",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  zIndex: 1100,
  padding: "0 16px",
});

const EnvText = styled(Typography)({
  marginRight: "12px",
  color: `black !important`,
});

const EnvMessage = styled(Typography)({
  color: `black !important`,
});

export default observer(Banner);
